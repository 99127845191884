// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  createTestQuestion,
  deleteTestQuestion,
  getTestContents,
  getTestOverview,
  updateTestOverview,
  updateTestQuestion,
  publishTest,
  incubateTest,
  draftTest,
  refreshTestOverview,
  getTestApplicants,
  deleteTest,
  archiveTest,
  reorderQuestions,
  updateTestPercentiles,
  changeTestOwnership,
} from "./testSingle.actions";
import {
  type testSingleOverviewType,
  type testSingleQuestionsType,
  type testSingleQuestionsDeleteType,
  type testSingleQuestionType,
  testSingleApplicantsType,
} from "../testSingle.types";
import { getEmptyInput } from "../hooks/useQuestionEditorSlice";
import {
  formatMicrodimensionOption,
  isSpeechProcessingMicrodim,
} from "../common/testSingle.functions";
import { microdimensionType } from "common/types/data.types";

interface TestSingleType {
  testSingleOverview: testSingleOverviewType;
  testSingleQuestions: testSingleQuestionsType;
  testSingleQuestionsDelete: testSingleQuestionsDeleteType;
  testSingleQuestion: testSingleQuestionType;
  applicants: testSingleApplicantsType;
}

const initialState: TestSingleType = {
  testSingleOverview: {
    value: null,
    processing: false,
    loading: false,
    error: null,
  },
  testSingleQuestions: {
    value: null,
    processing: false,
    reordering: false,
    loading: false,
    error: null,
  },
  testSingleQuestionsDelete: {
    value: null,
    processing: false,
    error: null,
  },
  testSingleQuestion: {
    value: null,
    index: null,
    state: null,
    processing: false,
    loading: false,
    error: null,
  },
  applicants: {
    value: null,
    processing: false,
    loading: true,
    error: null,
  },
};

export const testSingle = createSlice({
  name: "testSingle",
  initialState,
  reducers: {
    addTestQuestion: (state) => {
      state.testSingleQuestion = {
        ...initialState.testSingleQuestion,
        value: getEmptyInput(
          state.testSingleOverview.value?.template?.enum
            ? state.testSingleOverview.value?.template?.enum
            : ""
        ),
        state: "new",
      };
    },
    selectTestQuestion: (state, action) => {
      state.testSingleQuestion = {
        ...initialState.testSingleQuestion,
        value: {
          ...action.payload.value,
          microDims: action.payload.value.microDims.map(
            (m: microdimensionType) =>
              formatMicrodimensionOption(
                m,
                isSpeechProcessingMicrodim(
                  m,
                  action.payload.value?.preinterviewConfig?.isSpeechProcessing
                )
              )
          ),
        },
        index: action.payload.index,
        state: "edit",
      };
    },
    duplicateTestQuestion: (state) => {
      state.testSingleQuestion = {
        ...state.testSingleQuestion,
        state: "duplicate",
      };
    },
    setTestQuestionDelete: (state, action) => {
      state.testSingleQuestionsDelete.value = action.payload;
    },
    clearTestQuestion: (state) => {
      state.testSingleQuestion = initialState.testSingleQuestion;
    },
    clearTestSingleQuestionError: (state) => {
      state.testSingleQuestion.error = initialState.testSingleQuestion.error;
    },
    clearTestQuestionsDelete: (state) => {
      state.testSingleQuestionsDelete = initialState.testSingleQuestionsDelete;
    },
    clearTestSingleOverview: (state) => {
      state.testSingleOverview = initialState.testSingleOverview;
    },
    clearTestSingleOverviewError: (state) => {
      state.testSingleOverview.error = initialState.testSingleOverview.error;
    },
    clearTestSingleContents: (state) => {
      state.testSingleQuestions = initialState.testSingleQuestions;
      state.testSingleQuestion = initialState.testSingleQuestion;
    },
    clearTestApplicants: (state) => {
      state.applicants = initialState.applicants;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestOverview.pending, (state) => {
        state.testSingleOverview.loading = true;
      })
      .addCase(getTestOverview.fulfilled, (state, { payload }) => {
        state.testSingleOverview.value = payload;
        state.testSingleOverview.loading = false;
      })
      .addCase(refreshTestOverview.fulfilled, (state, { payload }) => {
        state.testSingleOverview.value = payload;
      })
      .addCase(getTestOverview.rejected, (state, { payload }) => {
        state.testSingleOverview.error = payload;
        state.testSingleOverview.loading = false;
      })
      .addCase(updateTestOverview.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(updateTestOverview.fulfilled, (state, { payload }) => {
        state.testSingleOverview.value = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(updateTestOverview.rejected, (state, { payload }) => {
        state.testSingleOverview.error = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(updateTestPercentiles.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(updateTestPercentiles.fulfilled, (state, { payload }) => {
        state.testSingleOverview.value = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(updateTestPercentiles.rejected, (state, { payload }) => {
        state.testSingleOverview.error = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(getTestContents.pending, (state) => {
        state.testSingleQuestions.loading = true;
      })
      .addCase(getTestContents.fulfilled, (state, { payload }) => {
        state.testSingleQuestions.value = payload;
        state.testSingleQuestions.loading = false;
      })
      .addCase(getTestContents.rejected, (state, { payload }) => {
        state.testSingleQuestions.error = payload;
        state.testSingleQuestions.loading = false;
      })
      .addCase(deleteTestQuestion.pending, (state) => {
        state.testSingleQuestionsDelete.processing = true;
      })
      .addCase(deleteTestQuestion.fulfilled, (state) => {
        state.testSingleQuestionsDelete.processing = false;
        state.testSingleQuestionsDelete.value = null;
      })
      .addCase(deleteTestQuestion.rejected, (state, { payload }) => {
        state.testSingleQuestionsDelete.error = payload;
        state.testSingleQuestionsDelete.processing = false;
      })
      .addCase(createTestQuestion.pending, (state) => {
        state.testSingleQuestion.processing = true;
      })
      .addCase(createTestQuestion.fulfilled, (state, { payload }) => {
        state.testSingleQuestion.processing = false;
        state.testSingleQuestion = {
          ...initialState.testSingleQuestion,
          value: payload.value,
          index: payload.index,
          state: "edit",
        };
      })
      .addCase(createTestQuestion.rejected, (state, { payload }) => {
        state.testSingleQuestion.error = payload;
        state.testSingleQuestion.processing = false;
      })
      .addCase(updateTestQuestion.pending, (state) => {
        state.testSingleQuestion.processing = true;
      })
      .addCase(updateTestQuestion.fulfilled, (state, action) => {
        state.testSingleQuestion = {
          ...state.testSingleQuestion,
          processing: false,
          error: false,
          value: { ...state.testSingleQuestion.value, ...action.payload },
        };
      })
      .addCase(updateTestQuestion.rejected, (state, { payload }) => {
        state.testSingleQuestion.error = payload;
        state.testSingleQuestion.processing = false;
      })
      .addCase(publishTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(publishTest.fulfilled, (state, { payload }) => {
        // @ts-ignore
        state.testSingleOverview.value = {
          ...state.testSingleOverview.value,
          // @ts-ignore
          state: payload?.state,
          // @ts-ignore
          publishedForCompanies: payload?.publishedForCompanies,
        };
        state.testSingleOverview.processing = false;
      })
      .addCase(publishTest.rejected, (state, { payload }) => {
        state.testSingleOverview.error = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(incubateTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(incubateTest.fulfilled, (state) => {
        state.testSingleOverview.processing = false;
      })
      .addCase(incubateTest.rejected, (state, { payload }) => {
        state.testSingleOverview.error = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(draftTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(draftTest.fulfilled, (state) => {
        state.testSingleOverview.processing = false;
      })
      .addCase(draftTest.rejected, (state, { payload }) => {
        state.testSingleOverview.error = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(deleteTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(deleteTest.fulfilled, (state) => {
        state.testSingleOverview.processing = false;
      })
      .addCase(deleteTest.rejected, (state, { payload }) => {
        state.testSingleOverview.error = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(archiveTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(archiveTest.fulfilled, (state, { payload }) => {
        // @ts-ignore
        state.testSingleOverview.value = {
          ...state.testSingleOverview.value,
          // @ts-ignore
          state: payload?.state,
        };
        state.testSingleOverview.processing = false;
      })
      .addCase(archiveTest.rejected, (state, { payload }) => {
        state.testSingleOverview.error = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(getTestApplicants.pending, (state) => {
        state.applicants.loading = true;
      })
      .addCase(getTestApplicants.fulfilled, (state, action) => {
        state.applicants.loading = false;
        state.applicants.value = action.payload;
      })
      .addCase(getTestApplicants.rejected, (state, { payload }: any) => {
        state.applicants.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.applicants.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(reorderQuestions.pending, (state, action) => {
        // @ts-ignore
        state.testSingleQuestions.reordering = true;
      })
      .addCase(reorderQuestions.fulfilled, (state, action) => {
        // @ts-ignore
        state.testSingleQuestions.value = action.payload;
        state.testSingleQuestions.reordering = false;
      })
      .addCase(reorderQuestions.rejected, (state, action) => {
        // @ts-ignore
        state.testSingleQuestions.error = action.payload;
        state.testSingleQuestions.reordering = false;
      })
      .addCase(changeTestOwnership.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(changeTestOwnership.fulfilled, (state, { payload }) => {
        state.testSingleOverview.processing = false;
        // @ts-ignore
        state.testSingleOverview.value = {
          ...state.testSingleOverview.value,
          // @ts-ignore
          companyId: payload?.companyId,
        };
      })
      .addCase(changeTestOwnership.rejected, (state, { payload }) => {
        state.testSingleQuestionsDelete.error = payload;
        state.testSingleOverview.processing = false;
      });
  },
});

export const {
  clearTestSingleOverview,
  selectTestQuestion,
  addTestQuestion,
  duplicateTestQuestion,
  clearTestQuestion,
  clearTestQuestionsDelete,
  clearTestSingleContents,
  clearTestApplicants,
  clearTestSingleOverviewError,
  clearTestSingleQuestionError,
  setTestQuestionDelete,
} = testSingle.actions;

export default testSingle.reducer;
