import { FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";

type typeProps = {
  children: ReactNode;
  type: "link" | "component";
  handleCheckOption?: () => void;
  className?: string;
  link?: string;
};
const CustomDropdownOption: FunctionComponent<typeProps> = (props) => {
  const { children, type, handleCheckOption, className, link } = props;

  return type === "component" ? (
    <div
      onClick={() => (handleCheckOption ? handleCheckOption() : null)}
      className={className}
    >
      {children}
    </div>
  ) : type === "link" ? (
    <Link to={link || ""} className={className}>
      {children}
    </Link>
  ) : null;
};

export default CustomDropdownOption;
