import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";
import { Navigate } from "react-router";
import PositionTrackingWrapper from "../wrappers/PositionTrackingWrapper/PositionTrackingWrapper";

const PositionWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/PositionWrapper/PositionWrapper"),
    ),
);
const PositionOverview: FC | any = lazy(() =>
  lazyRetry(() => import("../pages/PositionOverview/PositionOverview")),
);
const HiringFlowEditor: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/HiringFlowEditor/HiringFlowEditor"),
    ),
);
const HiringFlowModule: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../components/HiringFlowModule/HiringFlowModule"),
    ),
);

const CandidateList: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/CandidateList/CandidateList"),
    ),
);
const PositionSurveyResults: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSurveyResults/PositionSurveyResults"),
    ),
);
const PositionEmailSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/PositionEmailSettings"),
    ),
);
const PositionCompetenciesSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/PositionCompetenciesSettings"),
    ),
);

const PositionSettingsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/PositionSettingsWrapper/PositionSettingsWrapper"
        ),
    ),
);

const PositionEmailTemplateEditorPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../moduleSpecificComponents/PositionEmailTemplateEditorPage/PositionEmailTemplateEditorPage"
        ),
    ),
);

const PositionStepEmailTemplateEditorPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../moduleSpecificComponents/PositionStepEmailTemplateEditorPage/PositionStepEmailTemplateEditorPage"
        ),
    ),
);

const PositionAnalyticsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/PositionAnalyticsWrapper/PositionAnalyticsWrapper"
        ),
    ),
);

const PositionAnalytics: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/PositionAnalytics/PositionAnalytics"),
    ),
);

const PositionGeneralSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/PositionGeneralSettings"),
    ),
);

const PositionRemindersSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/PositionRemindersSettings"),
    ),
);

const PositionMembersSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/PositionMembersSettings"),
    ),
);

const RequirementsSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/RequirementsSettings"),
    ),
);

const PositionIntegrationsSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/PositionIntegrationsSettings"),
    ),
);

const PositionSimulationDemo: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSimulationDemo/PositionSimulationDemo"),
    ),
);
const PositionSimulationWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/PositionSimulationWrapper/PositionSimulationWrapper"
        ),
    ),
);

const PositionResumeImportsSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/PositionResumeImportsSettings"),
    ),
);

const positionRoutes: routeType[] = [
  {
    path: "/position/:id",
    element: <PositionWrapper />,
    permissions: "GET_POSITIONS",
    meta: {
      layout: "app",
      type: "page",
      private: true,
      company: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <PositionOverview />,
        meta: {
          title: "Overview",
          type: "component",
          private: true,
          company: true,
        },
      },
      {
        path: "tracking",
        element: <PositionTrackingWrapper />,
        redirect: true,
        meta: {
          title: "Hiring Flow",
          type: "component",
          private: true,
          company: true,
        },
        children: [
          {
            path: "step/:stepId",
            element: <CandidateList />,
            permissions: "GET_APPLICANTS",
            meta: {
              title: "Applicants List",
              type: "component",
              private: true,
              company: true,
            },
          },
        ],
      },
      {
        path: "hiring-flow",
        element: <HiringFlowEditor />,
        redirect: true,
        meta: {
          title: "Hiring Flow",
          type: "component",
          private: true,
          company: true,
        },
        children: [
          {
            path: "",
            element: <Navigate to={"step/1"} replace />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "step/:stepId",
            element: <HiringFlowModule />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
            children: [
              {
                path: "email-template-editor/:emailType",
                element: <PositionStepEmailTemplateEditorPage />,
                permissions: "EMAIL_TEMPLATE_GET",
                meta: {
                  type: "component",
                  company: true,
                  private: true,
                },
              },
              {
                path: "survey-results",
                element: <PositionSurveyResults />,
                permissions: "GET_APPLICANT_SURVEYS",
                meta: {
                  title: "Applicants",
                  type: "component",
                  private: true,
                  company: true,
                  roles: ["zenhire"],
                },
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        element: <PositionSettingsWrapper />,
        permissions: "UPDATE_POSITION",
        meta: {
          title: "Position settings",
          type: "component",
          private: true,
          company: true,
        },
        children: [
          {
            path: "",
            element: <PositionGeneralSettings />,
            allPermissionsCheck: ["GET_POSITIONS", "UPDATE_POSITION"],
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },

          {
            path: "requirements",
            element: <RequirementsSettings />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "competencies",
            element: <PositionCompetenciesSettings />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "emails",
            element: <PositionEmailSettings />,
            permissions: "EMAIL_TEMPLATE_GET",
            meta: {
              type: "component",
              private: true,
              company: true,
            },
            children: [
              // Position Template Editor
              {
                path: "email-template-editor/:emailType",
                element: <PositionEmailTemplateEditorPage />,
                meta: {
                  title: "Email template editor",
                  type: "component",
                  private: true,
                  company: true,
                },
              },
            ],
          },
          {
            path: "reminders",
            element: <PositionRemindersSettings />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "followers",
            element: <PositionMembersSettings />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },

          {
            path: "integrations",
            element: <PositionIntegrationsSettings />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "import-resumes",
            allPermissionsCheck: [
              "MANAGE_POSITION_APPLICANTS",
              "RESUME_IMPORT",
            ],
            element: <PositionResumeImportsSettings />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
        ],
      },
      {
        path: "analytics",
        element: <PositionAnalyticsWrapper />,
        allPermissionsCheck: ["GET_POSITIONS", "GET_POSITION_STATS"],
        meta: {
          title: "Position analytics",
          type: "component",
          private: true,
          company: true,
        },
        children: [
          {
            path: "",
            element: <PositionAnalytics />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/position/:id/hiring-flow/step/:stepId/simulation",
    element: <PositionSimulationWrapper />,
    meta: {
      layout: "blank",
      type: "page",
      private: true,
      company: true,
    },
    children: [
      {
        path: "",
        element: <Navigate to="demo" replace />,
        meta: {
          layout: "blank",
          type: "page",
          private: true,
          company: true,
        },
      },
      {
        path: "demo",
        element: <PositionSimulationDemo />,
        meta: {
          title: "Assessment demo",
          layout: "blank",
          private: true,
          company: true,
        },
      },
    ],
  },
];

const positionTemplatesRoutes: routeType[] = [
  {
    path: "/position-template/:id",
    element: <PositionWrapper positionTemplate />,
    permissions: "MANAGE_POSITION_TEMPLATES",
    meta: {
      layout: "app",
      type: "page",
      private: true,
      company: true,
    },
    children: [
      {
        path: "",
        element: <PositionOverview positionTemplate />,
        meta: {
          title: "Overview",
          type: "component",
          private: true,
          company: true,
        },
      },
      {
        path: "hiring-flow",
        element: <HiringFlowEditor positionTemplate />,
        redirect: true,
        meta: {
          title: "Hiring Flow",
          type: "component",
          private: true,
          company: true,
        },
        children: [
          {
            path: "",
            element: <Navigate to={"step/1"} replace />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "step/:stepId",
            element: <HiringFlowModule positionTemplate />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
            children: [
              {
                path: "email-template-editor/:emailType",
                permissions: "EMAIL_TEMPLATE_GET",
                element: (
                  <PositionStepEmailTemplateEditorPage positionTemplate />
                ),

                meta: {
                  type: "component",
                  company: true,
                  private: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        element: <PositionSettingsWrapper positionTemplate />,
        meta: {
          title: "Position settings",
          type: "component",
          private: true,
          company: true,
        },
        children: [
          {
            path: "",
            element: <PositionGeneralSettings positionTemplate />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "emails",
            element: <PositionEmailSettings />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
            children: [
              {
                path: "email-template-editor/:emailType",
                element: <PositionEmailTemplateEditorPage positionTemplate />,
                meta: {
                  title: "Email template editor",
                  type: "component",
                  private: true,
                  company: true,
                },
              },
            ],
          },
          {
            path: "requirements",
            element: <RequirementsSettings positionTemplate />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "reminders",
            element: <PositionRemindersSettings positionTemplate />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "followers",
            element: <PositionMembersSettings positionTemplate />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "competencies",
            element: <PositionCompetenciesSettings positionTemplate />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/position-template/:id/hiring-flow/step/:stepId/simulation",
    element: <PositionSimulationWrapper positionTemplate />,
    meta: {
      layout: "blank",
      type: "page",
      private: true,
      company: true,
    },
    children: [
      {
        path: "",
        element: <Navigate to="demo" replace />,
        meta: {
          layout: "blank",
          type: "page",
          private: true,
          company: true,
        },
      },
      {
        path: "demo",
        element: <PositionSimulationDemo positionTemplate />,
        meta: {
          title: "Assessment demo",
          layout: "blank",
          private: true,
          company: true,
        },
      },
    ],
  },
];

const allPositionRoute = {
  positionRoutes,
  positionTemplatesRoutes,
};

export default allPositionRoute;
