// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  archiveHiringFlow,
  createNewPositionModule,
  deletePosition,
  deletePositionSource,
  downloadPositionTemplate,
  downloadSingleReport,
  duplicatePosition,
  exportPdfReportsCandidateList,
  exportPositionCandidateList,
  exportPositionSurvey,
  followUnfollowPosition,
  getJobDescriptionAI,
  getPositionCandidateList,
  getPositionEmailTemplate,
  getPositionOverview,
  getPositionStats,
  getPositionStepEmailTemplate,
  getPositionSurvey,
  getResumesHistory,
  importPositionResumes,
  importPositionTemplate,
  positionActionsCandidate,
  positionDeleteStep,
  positionSaveAsTemplate,
  refreshPositionCandidateList,
  refreshPositionOverview,
  retryApplicantAssessment,
  savePositionEmailTemplate,
  savePositionStepEmailTemplate,
  startHiringFlow,
  updateAssessmentModuleFlow,
  updatePositionGeneralSettings,
  updatePositionInfoData,
  updatePositionJobStreetIntegration,
  updatePositionSources,
} from "./position.actions";
import {
  type hiringFlowModuleType,
  positionStatsType,
} from "../common/position.types";
import { errorType } from "common/types/reducer.types";

interface positionReducerType {
  positionOverview: {
    value: any | null;
    processing: boolean;
    loading: boolean;
    error: errorType | any;
    refreshLoading: boolean;
  };
  positionCandidateList: {
    value: any | null;
    loading: boolean;
    error: errorType | any;
  };
  positionUpdateList: {
    value: any | null;
    loading: boolean;
    error: errorType | any;
  };
  exportCandidateList: {
    value: any | null;
    loading: boolean;
    error: errorType | any;
  };
  downloadTemplate: {
    loading: boolean;
    error: errorType | any;
  };
  importTemplate: {
    loading: boolean;
    error: errorType | any;
  };
  importResumes: {
    loading: boolean;
    error: errorType | any;
  };
  importedResumesList: {
    value: any | null;
    loading: boolean;
    error: errorType | any;
  };
  module: hiringFlowModuleType;
  saveAsTemplate: {
    loading: boolean;
    error: errorType | any;
  };

  positionDeleteStep: {
    loading: boolean;
    response: string;
    error: errorType | any;
  };
  deletePosition: {
    loading: boolean;
    error: errorType | any;
  };
  positionCreateStep: {
    loading: boolean;
    error: errorType | any;
  };
  initialPositionStep: {
    stepIndex: any;
  };
  emailTemplate: {
    value: any;
    loading: boolean;
    processing: boolean;
    error: errorType | any;
  };
  singleReportDownload: {
    value: any;
    loading: boolean;
    error: errorType | any;
  };
  sourceState: {
    processing: boolean;
  };
  positionStats: {
    value: positionStatsType | null;
    loading: boolean;
    error: errorType | any;
  };
  positionJobDescriptionAI: {
    value: any | null;
    loading: boolean;
    error: errorType | any;
  };
  surveyList: {
    loading: boolean;
    values: any | null;
    error: errorType | any;
  };
  exportSurveyList: {
    value: any | null;
    loading: boolean;
    error: errorType | any;
  };
  positionAssistant: {
    idScroll: string | null;
  };
  followPosition: {
    loading: boolean;
    error: errorType | any;
  };
}

const initialState: positionReducerType = {
  positionOverview: {
    value: null,
    processing: false,
    loading: false,
    error: null,
    refreshLoading: false,
  },
  positionCandidateList: {
    value: null,
    loading: true,
    error: null,
  },
  positionUpdateList: {
    value: null,
    loading: false,
    error: null,
  },
  exportCandidateList: {
    value: null,
    loading: false,
    error: null,
  },
  downloadTemplate: {
    loading: false,
    error: null,
  },
  importTemplate: {
    loading: false,
    error: null,
  },
  importResumes: {
    loading: false,
    error: null,
  },
  importedResumesList: {
    loading: true,
    error: null,
    value: null,
  },
  module: {
    value: null,
    processing: false,
    loading: false,
    // @ts-
    error: null,
  },
  positionDeleteStep: {
    loading: false,
    error: null,
    response: "",
  },
  saveAsTemplate: {
    loading: false,
    error: null,
  },
  positionCreateStep: {
    loading: false,
    error: null,
  },
  initialPositionStep: {
    stepIndex: null,
  },
  emailTemplate: {
    value: null,
    loading: false,
    processing: false,
    error: null,
  },
  singleReportDownload: {
    value: null,
    loading: false,
    error: null,
  },
  sourceState: {
    processing: false,
  },
  positionStats: {
    value: null,
    loading: false,
    error: null,
  },
  positionJobDescriptionAI: {
    value: null,
    loading: false,
    error: false,
  },
  surveyList: {
    values: null,
    loading: true,
    error: null,
  },
  exportSurveyList: {
    value: null,
    loading: false,
    error: null,
  },
  positionAssistant: {
    idScroll: null,
  },
  followPosition: {
    loading: false,
    error: null,
  },
  deletePosition: {
    loading: false,
    error: null,
  },
};

export const position = createSlice({
  name: "position",
  initialState,
  reducers: {
    clearPositionOverview: (state) => {
      state.positionOverview = initialState.positionOverview;
    },
    clearHiringFlowModule: (state) => {
      state.module = initialState.module;
    },
    selectHiringFlowModule: (state, { payload }) => {
      state.module.value = payload;
    },
    clearDeleteStepResponse: (state) => {
      state.positionDeleteStep = initialState.positionDeleteStep;
    },
    clearCandidateList: (state) => {
      state.positionCandidateList = initialState.positionCandidateList;
    },
    clearExportCandidateList: (state) => {
      state.exportCandidateList.value = null;
    },
    clearSingleReportDownload: (state) => {
      state.singleReportDownload.value = null;
    },
    clearEmailTemplate: (state) => {
      state.emailTemplate.value = null;
    },
    setInitialPositionStep: (state, { payload }) => {
      state.initialPositionStep.stepIndex = payload;
    },
    updateEmailTemplateDesign: (state, { payload }) => {
      state.emailTemplate.value = {
        ...state.emailTemplate.value,
        html: payload.html,
        json: payload.json,
      };
    },
    clearJobDescriptionAI: (state) => {
      state.positionJobDescriptionAI = initialState.positionJobDescriptionAI;
    },
    updatePositionHiringFlows: (state, { payload }) => {
      state.positionOverview.value = {
        ...state.positionOverview.value,
        hiringFlows: payload.hiringFlows,
        canStart: payload.canStart,
        candidateApplicationOpenUntill: payload.candidateApplicationOpenUntill,
      };
    },
    addPositionAssistantScroll: (state, { payload }) => {
      state.positionAssistant.idScroll = payload;
    },
    updateCandidateListApplicationFollower: (state, { payload }) => {
      state.positionCandidateList = state.positionCandidateList || {};
      state.positionCandidateList.value =
        state.positionCandidateList.value || {};
      state.positionCandidateList.value = {
        ...state.positionCandidateList.value,
        paginatedData: {
          ...state.positionCandidateList.value.paginatedData,
          data: payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPositionOverview.pending, (state) => {
        state.positionOverview.loading = true;
      })
      .addCase(getPositionOverview.fulfilled, (state, { payload }) => {
        state.positionOverview.value = payload;
        state.positionOverview.loading = false;
      })
      .addCase(getPositionOverview.rejected, (state, { payload }) => {
        state.positionOverview.error = payload;
        state.positionOverview.loading = false;
      })
      .addCase(refreshPositionOverview.pending, (state, { payload }) => {
        state.positionOverview.refreshLoading = true;
      })
      .addCase(refreshPositionOverview.fulfilled, (state, { payload }) => {
        state.positionOverview.value = payload;
        state.positionOverview.refreshLoading = false;
      })

      .addCase(startHiringFlow.pending, (state) => {
        state.positionOverview.processing = true;
      })
      .addCase(startHiringFlow.fulfilled, (state, action) => {
        state.positionOverview.processing = false;
      })
      .addCase(startHiringFlow.rejected, (state, { payload }) => {
        state.positionOverview.error = payload;
        state.positionOverview.processing = false;
      })
      .addCase(archiveHiringFlow.pending, (state) => {
        state.positionOverview.processing = true;
      })
      .addCase(archiveHiringFlow.fulfilled, (state, action) => {
        state.positionOverview.processing = false;
      })
      .addCase(archiveHiringFlow.rejected, (state, { payload }) => {
        state.positionOverview.error = payload;
        state.positionOverview.processing = false;
      })
      .addCase(updateAssessmentModuleFlow.pending, (state) => {
        state.module.processing = true;
      })
      .addCase(updateAssessmentModuleFlow.fulfilled, (state, { payload }) => {
        state.module.processing = false;
        state.module.value = payload;
        state.module.error = initialState.module.error;
      })
      .addCase(updateAssessmentModuleFlow.rejected, (state, { payload }) => {
        state.module.error = payload;
        state.module.processing = false;
      })
      .addCase(updatePositionInfoData.pending, (state) => {
        state.module.processing = true;
      })
      .addCase(updatePositionInfoData.fulfilled, (state, { payload }) => {
        state.module.processing = false;
        state.positionOverview.value = payload;
        state.module.error = initialState.module.error;
      })
      .addCase(updatePositionInfoData.rejected, (state, { payload }) => {
        state.module.error = payload;
        state.module.processing = false;
      })
      .addCase(getPositionCandidateList.pending, (state) => {
        state.positionCandidateList.loading = true;
      })
      .addCase(getPositionCandidateList.fulfilled, (state, { payload }) => {
        state.positionCandidateList.value = payload;
        state.positionCandidateList.loading = false;
      })
      .addCase(getPositionCandidateList.rejected, (state, { payload }: any) => {
        state.positionCandidateList.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.positionCandidateList.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(refreshPositionCandidateList.fulfilled, (state, { payload }) => {
        state.positionCandidateList.value = payload;
      })
      .addCase(positionActionsCandidate.pending, (state) => {
        state.positionUpdateList.loading = true;
      })
      .addCase(positionActionsCandidate.fulfilled, (state, { payload }) => {
        state.positionCandidateList.value.paginatedData = {
          ...state.positionCandidateList.value.paginatedData,
          data: payload,
        };
        state.positionUpdateList.loading = false;
      })
      .addCase(positionActionsCandidate.rejected, (state, { payload }) => {
        state.positionUpdateList.error = payload;
        state.positionUpdateList.loading = false;
      })
      .addCase(retryApplicantAssessment.pending, (state) => {
        state.positionUpdateList.loading = true;
      })
      .addCase(retryApplicantAssessment.fulfilled, (state, { payload }) => {
        state.positionCandidateList.value.paginatedData = {
          ...state.positionCandidateList.value.paginatedData,
          data: payload,
        };
        state.positionUpdateList.loading = false;
      })
      .addCase(retryApplicantAssessment.rejected, (state, { payload }) => {
        state.positionUpdateList.error = payload;
        state.positionUpdateList.loading = false;
      })
      .addCase(exportPositionCandidateList.pending, (state) => {
        state.exportCandidateList.loading = true;
      })
      .addCase(exportPositionCandidateList.fulfilled, (state, { payload }) => {
        state.exportCandidateList.value = payload;
        state.exportCandidateList.loading = false;
      })
      .addCase(exportPositionCandidateList.rejected, (state, { payload }) => {
        state.exportCandidateList.error = payload;
        state.exportCandidateList.loading = false;
      })
      .addCase(exportPdfReportsCandidateList.pending, (state) => {
        state.exportCandidateList.loading = true;
      })
      .addCase(
        exportPdfReportsCandidateList.fulfilled,
        (state, { payload }) => {
          state.exportCandidateList.value = payload;
          state.exportCandidateList.loading = false;
        },
      )
      .addCase(exportPdfReportsCandidateList.rejected, (state, { payload }) => {
        state.exportCandidateList.error = payload;
        state.exportCandidateList.loading = false;
      })
      .addCase(downloadPositionTemplate.pending, (state) => {
        state.downloadTemplate.loading = true;
      })
      .addCase(downloadPositionTemplate.fulfilled, (state, { payload }) => {
        state.downloadTemplate.loading = false;
      })
      .addCase(downloadPositionTemplate.rejected, (state, { payload }) => {
        state.downloadTemplate.error = payload;
        state.downloadTemplate.loading = false;
      })
      .addCase(importPositionTemplate.pending, (state) => {
        state.importTemplate.loading = true;
      })
      .addCase(importPositionTemplate.fulfilled, (state, { payload }) => {
        state.importTemplate.loading = false;
      })
      .addCase(importPositionTemplate.rejected, (state, { payload }) => {
        state.importTemplate.error = payload;
        state.importTemplate.loading = false;
      })
      .addCase(positionDeleteStep.pending, (state) => {
        state.positionDeleteStep.loading = true;
      })
      .addCase(positionDeleteStep.fulfilled, (state, { payload }) => {
        state.positionDeleteStep.loading = false;
        state.positionDeleteStep.response = "success";
      })
      .addCase(positionDeleteStep.rejected, (state, { payload }) => {
        state.positionDeleteStep.error = payload;
        state.positionDeleteStep.loading = false;
      })
      .addCase(createNewPositionModule.pending, (state) => {
        state.positionCreateStep.loading = true;
      })
      .addCase(createNewPositionModule.fulfilled, (state, { payload }) => {
        state.positionCreateStep.loading = false;
      })
      .addCase(createNewPositionModule.rejected, (state, { payload }) => {
        state.positionCreateStep.error = payload;
        state.positionCreateStep.loading = false;
      })
      .addCase(duplicatePosition.pending, (state) => {
        state.positionOverview.processing = true;
      })
      .addCase(duplicatePosition.fulfilled, (state, { payload }) => {
        state.positionOverview.processing = false;
      })
      .addCase(duplicatePosition.rejected, (state, { payload }) => {
        state.positionOverview.error = payload;
        state.positionOverview.processing = false;
      })
      .addCase(getPositionEmailTemplate.pending, (state) => {
        state.emailTemplate.loading = true;
      })
      .addCase(getPositionEmailTemplate.fulfilled, (state, { payload }) => {
        state.emailTemplate.loading = false;
        state.emailTemplate.value = payload;
      })
      .addCase(getPositionEmailTemplate.rejected, (state, { payload }) => {
        state.emailTemplate.error = payload;
        state.emailTemplate.loading = false;
      })
      .addCase(savePositionEmailTemplate.pending, (state) => {
        state.emailTemplate.processing = true;
      })
      .addCase(savePositionEmailTemplate.fulfilled, (state, { payload }) => {
        state.emailTemplate.processing = false;
        // state.emailTemplate.value = payload;
      })
      .addCase(savePositionEmailTemplate.rejected, (state, { payload }) => {
        state.emailTemplate.error = payload;
        state.emailTemplate.processing = false;
      })
      .addCase(getPositionStepEmailTemplate.pending, (state) => {
        state.emailTemplate.loading = true;
      })
      .addCase(getPositionStepEmailTemplate.fulfilled, (state, { payload }) => {
        state.emailTemplate.loading = false;
        state.emailTemplate.value = payload;
      })
      .addCase(getPositionStepEmailTemplate.rejected, (state, { payload }) => {
        state.emailTemplate.error = payload;
        state.emailTemplate.loading = false;
      })
      .addCase(savePositionStepEmailTemplate.pending, (state) => {
        state.emailTemplate.processing = true;
      })
      .addCase(
        savePositionStepEmailTemplate.fulfilled,
        (state, { payload }) => {
          state.emailTemplate.processing = false;
          // state.emailTemplate.value = payload;
        },
      )
      .addCase(savePositionStepEmailTemplate.rejected, (state, { payload }) => {
        state.emailTemplate.error = payload;
        state.emailTemplate.processing = false;
      })
      .addCase(downloadSingleReport.pending, (state) => {
        state.singleReportDownload.loading = true;
      })
      .addCase(downloadSingleReport.fulfilled, (state, { payload }) => {
        state.singleReportDownload.value = payload;
        state.singleReportDownload.loading = false;
      })
      .addCase(downloadSingleReport.rejected, (state, { payload }) => {
        state.singleReportDownload.error = payload;
        state.singleReportDownload.loading = false;
      })

      .addCase(updatePositionSources.pending, (state) => {
        state.sourceState.processing = true;
      })
      .addCase(updatePositionSources.fulfilled, (state, payload) => {
        state.positionOverview.value = {
          ...state.positionOverview.value,
          // @ts-ignore
          sources: payload.payload.slug,
        };
        state.sourceState.processing = false;
      })
      .addCase(updatePositionSources.rejected, (state) => {
        state.sourceState.processing = false;
      })

      .addCase(deletePositionSource.pending, (state) => {
        state.sourceState.processing = true;
      })
      .addCase(deletePositionSource.fulfilled, (state, payload) => {
        state.sourceState.processing = false;
        state.positionOverview.value = {
          ...state.positionOverview.value,
          sources: payload.payload,
        };
      })
      .addCase(deletePositionSource.rejected, (state) => {
        state.sourceState.processing = false;
      })
      .addCase(getPositionStats.pending, (state) => {
        state.positionStats.loading = true;
      })
      .addCase(getPositionStats.fulfilled, (state, { payload }) => {
        state.positionStats.value = payload;
        state.positionStats.loading = false;
      })
      .addCase(getPositionStats.rejected, (state, { payload }) => {
        state.positionStats.error = payload;
        state.positionStats.loading = false;
      })
      .addCase(updatePositionGeneralSettings.pending, (state) => {
        state.module.processing = true;
      })
      .addCase(updatePositionGeneralSettings.fulfilled, (state, action) => {
        state.positionOverview.value = action?.payload;
        state.module.processing = false;
        state.module.error = initialState.module.error;
      })
      .addCase(updatePositionGeneralSettings.rejected, (state, { payload }) => {
        state.module.error = payload;
        state.module.processing = false;
      })

      .addCase(getJobDescriptionAI.pending, (state) => {
        state.positionJobDescriptionAI.loading = true;
      })
      .addCase(getJobDescriptionAI.fulfilled, (state, { payload }) => {
        state.positionJobDescriptionAI.value = payload;
        state.positionJobDescriptionAI.loading = false;
      })
      .addCase(getJobDescriptionAI.rejected, (state, { payload }) => {
        state.positionJobDescriptionAI.error = payload;
        state.positionJobDescriptionAI.loading = false;
      })

      .addCase(getPositionSurvey.pending, (state) => {
        state.surveyList.loading = true;
      })
      .addCase(getPositionSurvey.fulfilled, (state, { payload }) => {
        state.surveyList.values = payload;
        state.surveyList.loading = false;
      })
      .addCase(getPositionSurvey.rejected, (state, { payload }: any) => {
        state.surveyList.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.surveyList.loading = payload?.code === "ERR_CANCELED";
      })

      .addCase(exportPositionSurvey.pending, (state) => {
        state.exportSurveyList.loading = true;
      })
      .addCase(exportPositionSurvey.fulfilled, (state, { payload }) => {
        state.exportSurveyList.value = payload;
        state.exportSurveyList.loading = false;
      })
      .addCase(exportPositionSurvey.rejected, (state, { payload }) => {
        state.exportSurveyList.error = payload;
        state.exportSurveyList.loading = false;
      })
      .addCase(updatePositionJobStreetIntegration.pending, (state) => {
        state.module.processing = true;
      })
      .addCase(updatePositionJobStreetIntegration.fulfilled, (state) => {
        state.module.processing = false;
        state.module.error = initialState.module.error;
      })
      .addCase(
        updatePositionJobStreetIntegration.rejected,
        (state, { payload }) => {
          state.module.error = payload;
          state.module.processing = false;
        },
      )
      .addCase(importPositionResumes.pending, (state) => {
        state.importResumes.loading = true;
      })
      .addCase(importPositionResumes.fulfilled, (state, { payload }) => {
        state.importResumes.loading = false;
      })
      .addCase(importPositionResumes.rejected, (state, { payload }) => {
        state.importResumes.error = payload;
        state.importResumes.loading = false;
      })

      .addCase(getResumesHistory.pending, (state) => {
        state.importedResumesList.loading = true;
      })
      .addCase(getResumesHistory.fulfilled, (state, { payload }) => {
        state.importedResumesList.value = payload;
        state.importedResumesList.loading = false;
      })
      .addCase(getResumesHistory.rejected, (state, { payload }) => {
        state.importedResumesList.error = payload;
        state.importedResumesList.loading = false;
      })
      .addCase(followUnfollowPosition.pending, (state) => {
        state.followPosition.loading = true;
      })
      .addCase(followUnfollowPosition.fulfilled, (state, { payload }) => {
        state.positionOverview.value = {
          ...state.positionOverview.value,
          followers: payload,
        };
        state.followPosition.loading = false;
      })
      .addCase(followUnfollowPosition.rejected, (state, { payload }) => {
        state.followPosition.error = payload;
        state.followPosition.loading = false;
      })

      .addCase(positionSaveAsTemplate.pending, (state) => {
        state.saveAsTemplate.loading = true;
      })
      .addCase(positionSaveAsTemplate.fulfilled, (state, { payload }) => {
        state.saveAsTemplate.loading = false;
        state.saveAsTemplate.error = initialState.module.error;
      })
      .addCase(positionSaveAsTemplate.rejected, (state, { payload }) => {
        state.saveAsTemplate.error = payload;
        state.saveAsTemplate.loading = false;
      })
      .addCase(deletePosition.pending, (state) => {
        state.deletePosition.loading = true;
      })
      .addCase(deletePosition.fulfilled, (state, { payload }) => {
        state.deletePosition.loading = false;
      })
      .addCase(deletePosition.rejected, (state, { payload }) => {
        state.deletePosition.error = payload;
        state.deletePosition.loading = false;
      });
  },
});

export const {
  clearPositionOverview,
  clearHiringFlowModule,
  selectHiringFlowModule,
  clearDeleteStepResponse,
  setInitialPositionStep,
  clearCandidateList,
  clearExportCandidateList,
  clearSingleReportDownload,
  clearEmailTemplate,
  updateEmailTemplateDesign,
  clearJobDescriptionAI,
  updatePositionHiringFlows,
  addPositionAssistantScroll,
  updateCandidateListApplicationFollower,
} = position.actions;

export default position.reducer;
