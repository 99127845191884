import { useLocation } from "react-router";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../redux/reduxTypes";
import { setGlobalFilters } from "../../../../../../redux/globalFilters";
import { Fragment } from "react";
import { ReactComponent as CheckIco } from "assets/icons/check-ico-2.svg";
import { ReactComponent as CloseIco } from "assets/icons/close-ico-box.svg";
import { ReactComponent as FlagIco } from "assets/icons/flag-ico-2.svg";
import { ReactComponent as QuestionMark } from "assets/icons/question-mark-box.svg";
import { ReactComponent as HamburgerIco } from "assets/icons/hamburger-ico-3.svg";
import { ReactComponent as ZIco } from "assets/icons/z-ico.svg";
import { ReactComponent as CheckIcoBox } from "assets/icons/check-ico-box.svg";
import {
  fillIcoByState,
  getHiringStatusColors,
} from "../../../../../../common/helpers/functions.helpers";

const CandidateListStateFilters = () => {
  const location = useLocation();
  const globalFilters: any = useAppSelector(
    (state) => state.globalFilters[location.pathname],
  );
  const candidateListCount = useAppSelector(
    (store) => store?.position.positionCandidateList?.value?.states,
  );
  const candidateList = useAppSelector(
    (store) => store?.position.positionCandidateList,
  );

  const dispatch = useAppDispatch();
  const filtersOptions = [
    {
      label: "Passed",
      value: "passed",
      count: candidateListCount?.PASSED || 0,
      Icon: CheckIcoBox,
    },
    {
      label: "Completed",
      value: "completed",
      count: candidateListCount?.COMPLETED || 0,
      Icon: FlagIco,
    },
    {
      label: "Unscored",
      value: "unscored",
      count: candidateListCount?.UNSCORED || 0,
      Icon: QuestionMark,
    },
    {
      label: "In progress",
      value: "in_progress",
      count: candidateListCount?.IN_PROGRESS || 0,
      Icon: HamburgerIco,
    },
    {
      label: "Not started",
      value: "not_started",
      count: candidateListCount?.NOT_STARTED || 0,
      Icon: ZIco,
    },
    {
      label: "Hired",
      value: "hired",
      count: candidateListCount?.HIRED || 0,
      Icon: CheckIco,
    },
    {
      label: "Rejected",
      value: "rejected",
      count: candidateListCount?.REJECTED || 0,
      Icon: CloseIco,
    },
    // {
    //   label: "Timed out",
    //   value: "timedout",
    //   count: candidateListCount?.TIMEDOUT || 0,
    //   Icon: ClockIco,
    // },
  ];
  const applyFilters = (value: string) => {
    let allStates = globalFilters?.filters?.state
      ? [...globalFilters?.filters?.state]
      : [];
    if (value === "all") {
      allStates = [];
    } else if (allStates.includes(value)) {
      allStates = allStates.filter((item) => item !== value);
    } else {
      allStates.push(value);
    }
    dispatch(
      setGlobalFilters({
        location: location.pathname,
        filters: {
          ...globalFilters?.filters,
          state: allStates.filter((item) => item),
        },
        page: 1,
      }),
    );
  };

  return (
    <Fragment>
      <div
        className={`flex gap-1.5 justify-between text-sm font-[400] px-[6px] py-[5px] rounded-[10px] bg-z-grey-100 text-z-grey-550 cursor-default`}
      >
        Applicants in this step
        <span>
          {candidateList?.value?.paginatedData?.totalWithoutFilter || 0}
        </span>
      </div>
      {filtersOptions.map((item, index) => (
        <div
          key={index}
          className={`cursor-pointer flex gap-1.5 justify-between text-sm font-[400] px-[6px] py-[5px] rounded-[10px] hover:bg-z-grey-100 ${
            globalFilters?.filters?.state &&
            globalFilters?.filters?.state.includes(item?.value)
              ? `${item.value === "not_started" && "[&_path]:!fill-status-completed-text"} ${getHiringStatusColors(item.value)} ${fillIcoByState(item.value)}`
              : "text-z-grey-550 [&_path]:fill-z-grey-550"
          }
              `}
          onClick={() => applyFilters(item.value)}
        >
          {/*{item?.Icon ? <item.Icon className={"w-[20px]"} /> : null}*/}
          <div className={"flex gap-1.5 items-center"}>
            {item.Icon ? <item.Icon className={" w-[20px]"} /> : null}
            {item.label}
          </div>
          {(item.count || 0) >= 0 ? <span>{item.count}</span> : null}
        </div>
      ))}
    </Fragment>
  );
};

export default CandidateListStateFilters;
