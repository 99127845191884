import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const TestWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/TestWrapper/TestWrapper"),
    ),
);
const TestOverview: FC | any = lazy(() =>
  lazyRetry(() => import("../pages/TestOverview/TestOverview")),
);
const TestEditor: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/TestEditor/TestEditor")),
);
const TestPublishing: FC | any = lazy(() =>
  lazyRetry(() => import("../pages/TestPublishing/TestPublishing")),
);
const TestResultsHistory: FC | any = lazy(() =>
  lazyRetry(() => import("../pages/TestResultsHistory/TestResultsHistory")),
);
const QuestionEditorController: FC | any = lazy(() =>
  lazyRetry(
    () =>
      import("../components/QuestionEditorController/QuestionEditorController"),
  ),
);
const QuestionEditorLanding: FC | any = lazy(() =>
  lazyRetry(
    () => import("../components/QuestionEditorLanding/QuestionEditorLanding"),
  ),
);
const TestSettingsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../wrappers/TestSettingsWrapper/TestSettingsWrapper"),
    ),
);
const TestGeneralSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/TestSettings/TestGeneralSettings"),
    ),
);
const TestConfigurationSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/TestSettings/TestConfigurationSettings"),
    ),
);
const testSingleRoutes: routeType[] = [
  {
    path: ":id",
    element: <TestWrapper />,
    permissions: "GET_TESTS",
    meta: {
      layout: "app",
      type: "page",
      private: true,
      company: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <TestOverview />,
        meta: {
          title: "Overview",
          type: "component",
          private: true,
          company: true,
        },
      },
      {
        path: "content",
        element: <TestEditor />,
        permissions: "GET_TEST_CONTENTS",
        meta: {
          title: "Content",
          type: "component",
          private: true,
          company: true,
        },
        children: [
          {
            path: "",
            element: <QuestionEditorLanding />,
            meta: {
              title: "",
              type: "component",
              private: true,
              company: true,
            },
          },
          {
            path: "questions/:questionId",
            element: <QuestionEditorController />,
            meta: {
              title: "Question Editor",
              type: "component",
              private: true,
              company: true,
            },
          },
        ],
      },

      {
        path: "publishing",
        element: <TestPublishing />,
        permissions: [
          "PUBLISH_TEST",
          "INCUBATE_TEST",
          "ARCHIVE_TEST",
          "DELETE_TEST",
        ],
        meta: {
          title: "Publishing",
          type: "component",
          private: true,
          company: true,
        },
      },
      {
        path: "results-history",
        element: <TestResultsHistory />,
        permissions: "GET_TEST_TAKERS",
        meta: {
          title: "Results History",
          type: "component",
          private: true,
          company: true,
        },
      },
      {
        path: "settings",
        element: <TestSettingsWrapper />,
        // permissions: "UPDATE_POSITION",
        meta: {
          title: "Position settings",
          type: "component",
          private: true,
          company: true,
        },
        children: [
          {
            path: "",
            element: <TestGeneralSettings />,
            // allPermissionsCheck: ["GET_POSITIONS", "UPDATE_POSITION"],
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },

          {
            path: "configuration",
            element: <TestConfigurationSettings />,
            meta: {
              type: "component",
              private: true,
              company: true,
            },
          },
        ],
      },
    ],
  },
];

export default testSingleRoutes;
