import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";
import testSingleRoutes from "../../testSingle/routes";
import { Navigate } from "react-router";

const TestLibraryWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../wrappers/TestLibraryWrapper/TestLibraryWrapper")
    )
);

const TestWizard: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/TestWizard/TestWizard"))
);

const testLibraryRoutes: routeType[] = [
  {
    path: "/test-library/:testType",
    element: <TestLibraryWrapper />,
    permissions: [
      "GET_TESTS",
      "GET_TESTS_PUBLISHED",
      "GET_TESTS_DRAFT",
      "GET_TESTS_INCUBATION",
      "GET_TESTS_ARCHIVED",
    ],
    meta: {
      layout: "app",
      title: "Test library",
      type: "page",
      private: true,
      company: true,
    },
    children: [...testSingleRoutes],
  },
  {
    path: "/test-library",
    element: <Navigate to="/test-library/published" replace />,
    meta: {
      layout: "blank",
      type: "page",
      public: true,
    },
  },
  {
    path: "/create-test",
    element: <TestWizard />,
    permissions: "CREATE_TEST",
    meta: {
      layout: "app",
      title: "Create test",
      type: "page",
      private: true,
      company: true,
    },
  },
];

export default testLibraryRoutes;
