import ReactSelect, { MenuPosition } from "react-select";
import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIco } from "assets/icons/close-ico.svg";
import ScrollBar from "react-perfect-scrollbar";
import { ReactComponent as SearchIco } from "assets/icons/search-ico.svg";

type dataType = {
  _id: string;
  value: string;
  label: string;
  isDisabled?: boolean;
};
type propsTypes = {
  menuPosition?: MenuPosition;
  label?: string;
  selectedData?: dataType[];
  data: dataType[];
  onChange: (selectedValue: any) => void;
  invalid?: boolean;
  maxHeight?: string;
  disabled?: boolean;
  customRef?: React.MutableRefObject<any>;
  name?: string;
  onInputChange?: (e: string) => void;
  onKeyDown?: any;
  hideSelectedOptions?: boolean;
  placeholder?: string;
  CustomIndicator?: any;
  loading?: boolean;
};

const CustomIndicator = (props: any) => {
  return (
    <div className="custom-indicator ml-[3px]">
      <SearchIco />
    </div>
  );
};

const MultipleSelect = (props: propsTypes) => {
  const [options, setOptions] = useState(props.data);
  const [selectedData, setSelectedData] = useState<any[]>([]);

  useEffect(() => {
    // If selectedData is an array of strings, convert it to an array of objects
    if (
      Array.isArray(props.selectedData) &&
      typeof props.selectedData[0] === "string"
    ) {
      setSelectedData(
        (props.selectedData as any).map((value: any) => {
          const option = props.data.find((item) => item.value === value);
          return option || { value, label: value }; // Fallback if the value is not found in the data
        }),
      );
    } else {
      setSelectedData(props.selectedData || []);
    }
  }, [props.selectedData, props.data]);

  useEffect(() => {
    setOptions(props.data || []);
  }, [props.data]);

  const handleSelect = (value: any) => {
    props.onChange(value);
  };

  const handleRemoveValue = (valueName: any) => {
    const removedValue = selectedData?.filter(
      (val: any) => val.value !== valueName,
    );
    if (!removedValue) return;
    handleSelect(removedValue);
  };

  return (
    <div className="flex flex-col w-full">
      {props.label && (
        <p className="text-dark-gray text-sm font-light mb-1.5 select-none">
          {props.label}
        </p>
      )}
      <ReactSelect
        ref={props?.customRef || null}
        onInputChange={(e) =>
          props?.onInputChange ? props?.onInputChange(e) : undefined
        }
        onKeyDown={props?.onKeyDown ? props.onKeyDown : null}
        isMulti
        name={props.name || "multiple"}
        options={options}
        value={selectedData}
        isDisabled={props.disabled}
        hideSelectedOptions={props.hideSelectedOptions || true}
        onChange={(value: any) => props.onChange(value)}
        menuPosition={props.menuPosition ? props.menuPosition : "fixed"}
        isClearable={false}
        controlShouldRenderValue={false}
        components={{ DropdownIndicator: CustomIndicator }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#F1F1F1",
          },
        })}
        placeholder={props.placeholder ? `${props.placeholder}` : "Search"}
        menuPortalTarget={document.body}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            display: "flex",
            borderRadius: 10,
            backgroundColor: props.invalid ? "#CD4C4C1A" : "",
            borderColor: "#BBBBBB",
            "&:hover": "transparent",
            boxShadow: "none",
            height: "35px",
            "::-webkit-scrollbar": {
              width: "1px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#aaa",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#aaa",
            },
            cursor: props?.disabled ? "unset" : "pointer",
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#1A1A1A",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            order: 2,
            paddingLeft: "0px",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "#838383",
            fontSize: "13px",
            marginTop: "-1px",
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: "none",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 10,
            padding: 5,
            backgroundColor: "#1A1A1A",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 10,
            cursor: state.isDisabled ? "unset" : "pointer",
            backgroundColor: state.isFocused
              ? "#3B3B3B"
              : state.isDisabled
                ? "#868686"
                : "#1A1A1A",
            color: "white",
            wordBreak: "break-word",
          }),
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 51,
          }),
          menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
              width: "1px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#aaa",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#aaa",
            },
          }),
        }}
      />
      {!props.loading && selectedData?.length ? (
        <ScrollBar
          component="div"
          options={{
            wheelPropagation: true,
          }}
          className="mt-2 flex flex-wrap gap-2 p-1"
          style={{
            maxHeight: `${props.maxHeight}px`,
          }}
        >
          {selectedData?.map((val: any) => (
            <div
              key={val.value}
              className={`text-[12px] rounded-lg ${val.isDisabled ? "bg-z-grey-550 text-white" : "bg-main-color text-white"} p-1 flex items-center gap-2`}
            >
              {val.label}
              {!val.isDisabled ? (
                <div
                  className={
                    "rounded-full bg-white hover:bg-z-grey-50 w-[15px] h-[15px] min-w-[15px] min-h-[15px] flex justify-center items-center p-[0px] cursor-pointer"
                  }
                >
                  <CloseIco
                    onClick={() => handleRemoveValue(val.value)}
                    className={"w-[12px] h-[12px] [&_path]:fill-main-color"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </ScrollBar>
      ) : null}
    </div>
  );
};

export default MultipleSelect;
