import {
  Fragment,
  FunctionComponent,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, NavLink } from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import Spinner from "../spinners/Spinner";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import { useLocation, useNavigate, useParams } from "react-router";
import { useOutsideClickHandler } from "common/hooks/useOutsideClickHandler";
import { renderFileUrl } from "common/helpers/functions.helpers";
import { useAuthorization } from "common/hooks/useAuthorization";
import noImageImg from "assets/icons/no-image.svg";
import { ReactComponent as SettingsIco } from "assets/icons/cog-ico-4.svg";
import { ReactComponent as ArrowDownDropdown } from "assets/icons/arrow-down-3.svg";
import { companyItemType } from "../../features/companies/companies.type";
import { ImagePreview } from "../FilePreview/ImagePreview";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import zenhire from "assets/icons/zenhire-ico.png";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { ReactComponent as EmailIco } from "assets/icons/email-ico-3.svg";
import { ReactComponent as CompanyIco2 } from "assets/icons/company-ico-2.svg";
import { getInboxCount } from "../../features/inbox/reducer/inbox.actions";
import { ReactComponent as ProfilesIco } from "assets/icons/user-ico-grey.svg";
import { ReactComponent as ApplicationIco } from "assets/icons/document-ico-grey.svg";
import { ReactComponent as PositionIco } from "assets/icons/bag-ico-gray.svg";
import { ReactComponent as TemplateIco } from "assets/icons/hamburger-ico-4.svg";

type propTypes = {
  companyData: companyItemType | null;
  idCompany: string;
};
const ClientNavbar: FunctionComponent<propTypes> = (props) => {
  const { checkRoles } = useAuthorization();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.authentication.userData);
  const { companyData, idCompany } = props;
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const companyOverview = useAppSelector(
    (state) => state.company.companyOverview.value,
  );
  const key = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const personalInboxCount = useAppSelector(
    (state) => state.global?.inboxCount?.personalInboxCount?.unread,
  );
  const companyInboxCount = useAppSelector(
    (state) => state.global?.inboxCount?.companyInboxCount?.unread,
  );

  const { checkPermissions, allPermissionsCheck } = useAuthorization();
  const closeDropdownHandler = () => {
    setIsProfileDropdownOpen(false);
  };

  function closeMenu() {
    setTimeout(() => {
      closeDropdownHandler();
    }, 50);
  }

  const handleClickOutside = (event?: any) => {
    closeMenu();
  };

  useOutsideClickHandler(ref, handleClickOutside);

  const displayStyle = () => {
    return "relative z-50 block bg-black";
  };

  const navLinks = [
    {
      label: "Dashboard",
      to: "",
    },
    {
      label: "Candidates",
      activeLink:
        location?.pathname?.startsWith(`/${idCompany}/applicants`) ||
        location?.pathname?.startsWith(`/${idCompany}/applications`),
      to: `/${checkPermissions("GET_APPLICANTS") ? "applicants" : checkPermissions("GET_APPLICATIONS") ? "applications" : ""}`,
      permissions: ["GET_APPLICANTS", "GET_APPLICATIONS"],
      children: !allPermissionsCheck(["GET_APPLICANTS", "GET_APPLICATIONS"])
        ? []
        : [
            {
              label: "Profiles",
              link: `/${idCompany}/applicants`,
              permissions: "GET_APPLICANTS",
              checked: location?.pathname === `/${idCompany}/applicants`,
              Icon: ProfilesIco,
            },
            {
              label: "Applications",
              link: `/${idCompany}/applications`,
              permissions: "GET_APPLICATIONS",
              checked: location?.pathname === `/${idCompany}/applications`,
              Icon: ApplicationIco,
            },
          ],
    },
    {
      label: "Positions",
      activeLink: location?.pathname?.startsWith(`/${idCompany}/positions`),
      to: `/positions`,
      permissions: "GET_POSITIONS",
      children: !checkPermissions("VIEW_POSITION_TEMPLATES")
        ? []
        : [
            {
              label: "Positions",
              link: `/${idCompany}/positions`,
              permissions: "GET_POSITIONS",
              checked: location?.pathname === `/${idCompany}/positions`,
              Icon: PositionIco,
            },
            {
              label: "Templates",
              link: `/${idCompany}/positions/templates`,
              permissions: "VIEW_POSITION_TEMPLATES",
              checked:
                location?.pathname === `/${idCompany}/positions/templates`,
              Icon: TemplateIco,
            },
          ],
    },
    {
      label: "Test Library",
      to: "/test-library/published",
      permissions: "GET_TESTS",
    },
    {
      label: "Our Team",
      to: "/users",
      permissions: "GET_USERS",
    },
  ];

  const countInbox = (type: string) => {
    switch (type) {
      case "personal":
        return Number(personalInboxCount) || null;
      case "company":
        return Number(companyInboxCount) || null;
    }
  };

  const checkPermissionPersonalInbox = useMemo(() => {
    return (
      companyOverview?.emailAddress &&
      userData.value?.emailAddress &&
      userData.value?.company?._id === companyOverview?._id &&
      checkPermissions("EMAIL_INBOX")
    );
  }, [companyOverview, userData?.value]);

  const checkPermissionCompanyInbox = useMemo(() => {
    return (
      companyOverview?.emailAddress && checkPermissions("COMPANY_EMAIL_INBOX")
    );
  }, [companyOverview, userData?.value]);

  const availableInboxes = useMemo(() => {
    let inboxes = [];
    if (checkPermissionPersonalInbox) {
      inboxes.push({
        label: "Personal Inbox",
        functionOption: () => navigate(`/${idCompany}/inbox/personal`),
        checked: location?.pathname === `/${idCompany}/inbox`,
        childrenIcon: (
          <div className={"pr-0.5"}>
            <Avatar
              content={`${userData?.value?.name} ${userData?.value?.surname}`}
              avatar={userData?.value?.avatar}
              size={22}
              containerClass={"border-[1px] border-white"}
            />
          </div>
        ),
        lightText: userData?.value?.emailAddress || "",
        type: "personal",
      });
    }
    if (checkPermissionCompanyInbox) {
      inboxes.push({
        label: "Company Inbox",
        functionOption: () => navigate(`/${idCompany}/inbox/company`),
        checked: location?.pathname === `/${idCompany}/positions/templates`,
        childrenIcon: <CompanyIco2 className={"[&_path]:fill-white"} />,
        lightText: companyOverview?.emailAddress || "",
        type: "company",
      });
    }
    return inboxes;
  }, [userData, companyOverview]);

  const unreadMessage = useMemo(() => {
    return checkPermissionPersonalInbox && !checkPermissionCompanyInbox
      ? Number(personalInboxCount)
      : !checkPermissionPersonalInbox && checkPermissionCompanyInbox
        ? Number(companyInboxCount)
        : checkPermissionPersonalInbox && checkPermissionCompanyInbox
          ? Number(companyInboxCount) + Number(personalInboxCount)
          : 0;
  }, [
    personalInboxCount,
    companyInboxCount,
    checkPermissionPersonalInbox,
    checkPermissionCompanyInbox,
  ]);

  useEffect(() => {
    if (
      key?.companyId &&
      availableInboxes?.length &&
      !personalInboxCount
      // && !checkRoles("zenhire")
    ) {
      const companyId = key?.companyId;
      dispatch(
        getInboxCount(
          companyId
            ? {
                companyId: companyId,
              }
            : {},
        ),
      );
    }
  }, [availableInboxes]);

  return (
    <>
      <div
        ref={ref}
        style={{
          background: companyData?.enableHeaderColor
            ? companyData?.headerColor
            : companyData?.color
              ? companyData?.color
              : "black",
        }}
        className={`${displayStyle()} bg-black z-[40] border-b border-z-grey-200`}
      >
        <div className="flex items-center m-auto h-[46px] px-[20px]">
          <NavLink
            to={`/${idCompany}`}
            className="flex items-center cursor-pointer gap-2 mr-auto h-full"
          >
            <ImagePreview
              src={
                companyData?.headerLogo && companyData?.enableHeaderLogo
                  ? renderFileUrl(companyData.headerLogo)
                  : !companyData?.headerLogo && companyData?.enableHeaderLogo
                    ? noImageImg
                    : companyData?.logo
                      ? renderFileUrl(companyData.logo)
                      : zenhire
              }
              className="max-h-[30px]"
              alt={"company-ico"}
              noImageClass={
                "!bg-transparent !w-10 rounded-[50%] !h-10  border-none"
              }
            />
          </NavLink>

          <nav className="text-[13px] font-[500] flex h-full">
            {navLinks
              .filter((link) =>
                (link.permissions && checkPermissions(link.permissions)) ||
                !link.permissions
                  ? link
                  : null,
              )
              .map((link, index) =>
                link.children?.length ? (
                  <Fragment key={index}>
                    <CustomDropdown
                      customToggler={
                        <Link
                          to={`/${idCompany}${link.to}`}
                          key={index}
                          className={`h-full py-[10px] px-3.5 client-nav-link flex items-center relative cursor-pointer justify-center gap-2 ${
                            link.activeLink ? "zh-active-menu-link" : ""
                          }`}
                          style={{
                            color: companyData?.linkColor
                              ? companyData?.linkColor
                              : "#FFFFFF80",
                          }}
                        >
                          {link.label}
                          <ArrowDownDropdown
                            className={`${companyData?.linkColor ? "[&_path]:fill-main-link-color" : "[&_path]:fill-[#FFFFFF80]"} mt-[1px]`}
                          />
                          <div
                            className={
                              "zh-active-menu-link-border hidden border-b-[2px] border-main-link-color absolute bottom-0.5 left-0 w-full"
                            }
                          ></div>
                        </Link>
                      }
                      functionOption={link.children.filter((link) =>
                        (link.permissions &&
                          checkPermissions(link.permissions)) ||
                        !link.permissions
                          ? link
                          : null,
                      )}
                      menuClassName={`!mt-[7px]`}
                      toggleOnClick={false}
                    />
                  </Fragment>
                ) : (
                  <NavLink
                    key={index}
                    to={`/${idCompany}${link.to}`}
                    className={({ isActive }) =>
                      `py-[10px] px-3.5 client-nav-link flex items-center relative ${
                        isActive ? "zh-active-menu-link" : ""
                      }`
                    }
                    end={!link.to}
                    style={{
                      color: companyData?.linkColor
                        ? companyData?.linkColor
                        : "#FFFFFF80",
                    }}
                  >
                    {link.label}
                    <div
                      className={
                        "zh-active-menu-link-border hidden border-b-[2px] border-main-link-color absolute bottom-0.5 left-0 w-full"
                      }
                    ></div>
                  </NavLink>
                ),
              )}
          </nav>
          <div className="relative flex items-center gap-2 ml-7 h-full">
            <div className={"flex items-center h-full"}>
              {checkPermissions("EMAIL_INBOX") ||
              checkPermissions("COMPANY_EMAIL_INBOX") ? (
                <CustomDropdown
                  containerTooltipClassName={"!h-full"}
                  customToggler={
                    <Link
                      to={`/${idCompany}/inbox/${
                        checkRoles("zenhire") ||
                        !availableInboxes?.find(
                          (inbox: { type: string }) =>
                            inbox.type === "personal",
                        )
                          ? "company"
                          : "personal"
                      }`}
                      className={`h-full py-[10px] px-1 client-nav-link flex items-center relative cursor-pointer justify-center gap-2 ${
                        location?.pathname.startsWith(`/${idCompany}/inbox`)
                          ? "zh-active-menu-link"
                          : ""
                      }`}
                      style={{
                        color: companyData?.linkColor
                          ? companyData?.linkColor
                          : "#FFFFFF80",
                      }}
                    >
                      {unreadMessage > 0 ? (
                        <span
                          style={{
                            fontSize: unreadMessage > 99 ? "9px" : "10px",
                          }}
                          className={
                            "text-white bg-z-red-light rounded-[3px] absolute top-[8px] flex items-center justify-center font-[500] w-content px-[1px] z-[50]"
                          }
                        >
                          {unreadMessage > 99 ? "99+" : unreadMessage}
                        </span>
                      ) : null}
                      <div className={"relative"}>
                        <EmailIco
                          className={`client-settings-ico [&_path]:fill-main-link-color`}
                        />
                      </div>
                      {!availableInboxes?.length ? null : (
                        <ArrowDownDropdown
                          className={`[&_path]:fill-main-link-color mt-[1px]`}
                        />
                      )}
                      <div
                        className={
                          "zh-active-menu-link-border hidden border-b-[2px] border-main-link-color absolute bottom-0.5 left-0 w-full"
                        }
                      ></div>
                    </Link>
                  }
                  functionOption={availableInboxes?.map((inbox) => {
                    return {
                      ...inbox,
                      count: countInbox(inbox.type),
                    };
                  })}
                  menuClassName={`!mt-[7px]`}
                  toggleOnClick={false}
                />
              ) : null}
              {checkPermissions("UPDATE_COMPANY") ? (
                <NavLink
                  to={`/${idCompany}/settings`}
                  className={`py-[10px] px-3.5 client-nav-link flex items-center relative h-full ${
                    location?.pathname.startsWith(`/${idCompany}/settings`)
                      ? "zh-active-menu-link"
                      : ""
                  }`}
                  style={{
                    color: companyData?.linkColor
                      ? companyData?.linkColor
                      : "#FFFFFF80",
                  }}
                >
                  <SettingsIco
                    className={`client-settings-ico [&_path]:fill-main-link-color`}
                  />
                  {location?.pathname.startsWith(`/${idCompany}/settings`) ? (
                    <div
                      className={
                        "zh-active-menu-link-border hidden border-b-[2px] border-main-link-color absolute bottom-0.5 left-0 w-full"
                      }
                    ></div>
                  ) : null}
                </NavLink>
              ) : null}
            </div>
            {userData.loading || userData.processing || !userData.value ? (
              <Spinner classIcon={"w-[30px] h-[30px]"} />
            ) : (
              <>
                <div
                  className="cursor-pointer w-[30px] h-[30px]"
                  onClick={() => {
                    setIsProfileDropdownOpen((prevState) => !prevState);
                  }}
                >
                  <Avatar
                    content={`${userData.value.name} ${userData.value.surname}`}
                    avatar={userData.value.avatar}
                    size={30}
                    containerClass={userData.value.avatar ? "" : "border"}
                  />
                </div>

                {isProfileDropdownOpen && (
                  <div
                    className="absolute top-12 right-0 z-50"
                    onMouseLeave={closeDropdownHandler}
                  >
                    <ProfileDropdown
                      img={userData.value.avatar}
                      closeDropdown={closeDropdownHandler}
                      contentName={`${userData.value.name} ${userData.value.surname}`}
                      role={userData.value.role.description}
                      fullName={`${userData.value.name} ${userData.value.middleName} ${userData.value.surname}`}
                      settingsLink={`/${idCompany}/profile-settings/general`}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientNavbar;
