import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import { createUserType } from "../users.types";
import { RootState } from "redux/store";
import { fetchUsers } from "./users.services";
import { errorRequestType } from "common/types/data.types";
import { isStringEmpty } from "common/utility/Utils";

export const getUsers = createAsyncThunk<
  any,
  {
    companyId?: string;
    paramsArray?: any;
    signal?: any;
  },
  { state: RootState }
>(
  "users/getUsers",
  async ({ companyId, signal, paramsArray }, { rejectWithValue }) => {
    try {
      const params = {
        ...(paramsArray?.filters?.role && {
          role: paramsArray?.filters?.role.value,
        }),
        ...(paramsArray?.filters?.status && {
          status: paramsArray?.filters?.status.toUpperCase(),
        }),
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.sort && {
          sort: paramsArray?.sort,
        }),
        ...(paramsArray?.filters?.permissions?.length && {
          permissions: `${paramsArray?.filters.permissions.map(
            (item: string) => item,
          )}`,
        }),
        ...(paramsArray?.filters?.company && {
          company: paramsArray?.filters?.company,
        }),
        page: paramsArray?.page || 1,
        ...(paramsArray?.filters?.state && {
          roleType: paramsArray?.filters?.state,
        }),
        limit: 50,
        ...(companyId && {
          company: companyId,
        }),
      };

      const response = await fetchUsers(params, signal);
      if (!response?.data) return;
      return response?.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);
export const createAdminUser = createAsyncThunk<
  any,
  createUserType,
  {
    rejectValue: errorRequestType;
  }
>(
  "users/createAdminUser",
  async (
    {
      name,
      middleName,
      surname,
      email,
      emailAddress,
      password,
      permissions,
      role,
      company,
    }: createUserType,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.post("/users", {
        name,
        middleName,
        surname,
        email,
        ...(!isStringEmpty(emailAddress) ? { emailAddress: emailAddress } : {}),
        password,
        permissions,
        role: { name: role },
        ...(company && {
          company: company,
        }),
      });
      dispatch(
        setToast({
          message:
            "Successfully created a new user. A verification email will be sent to their email address",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  },
);

export const createUserEmailAddress = createAsyncThunk<
  any,
  { userId: string; emailAddress: string },
  {
    state: RootState;
  }
>(
  "company/createUserEmailAddress",
  async ({ userId, emailAddress }, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.post(`/email/users/${userId}/email-address`, {
        emailAddress: emailAddress,
      });
      dispatch(
        setToast({
          message: `Successfully created ZH email`,
          type: "success",
        }),
      );
      const values = getState().users.usersList.values?.data;
      return values
        ? values?.map((user) =>
            user._id === userId
              ? {
                  ...user,
                  emailAddress: emailAddress,
                }
              : user,
          )
        : null;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

type typeActivateDeactivate = {
  id: string;
  type: string;
};
export const activateDeactivateUser = createAsyncThunk<
  any,
  typeActivateDeactivate,
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "users/activateDeactivateUser",
  async ({ id, type }, { dispatch, rejectWithValue, getState }) => {
    try {
      const response: any = await http.patch(`/users/${id}/${type}`);
      dispatch(
        setToast({
          message: `User successfully ${type}d.`,
          type: "success",
        }),
      );

      const values = getState().users.usersList.values?.data;
      return values?.map((user) =>
        user._id === response.data._id
          ? {
              ...user,
              userStatus: response?.data?.userStatus,
            }
          : user,
      );
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

type typeUpdateUser = {
  email: string;
  permissions: string[];
};
export const updateUserPermissions = createAsyncThunk<
  any,
  typeUpdateUser,
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "users/updateUser",
  async ({ email, permissions }, { dispatch, rejectWithValue, getState }) => {
    try {
      const response: any = await http.patch(`/users/admins/permissions`, {
        email,
        permissions,
      });
      dispatch(
        setToast({
          message: `User successfully updated`,
          type: "success",
        }),
      );
      const values = getState().users.usersList.values?.data;

      return values?.map((user) =>
        user._id === response.data._id
          ? { ...user, permissions: response.data.permissions }
          : user,
      );
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const updateUserRoleTemplate = createAsyncThunk<
  any,
  { email: string; roleId: string },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "users/updateUserRoleTemplate",
  async ({ roleId, email }, { dispatch, rejectWithValue, getState }) => {
    try {
      const response: any = await http.patch(`/users/admins/role`, {
        email,
        roleId,
      });
      dispatch(
        setToast({
          message: `User successfully updated`,
          type: "success",
        }),
      );
      const values = getState().users.usersList.values?.data;
      return values?.map((user) =>
        user._id === response.data._id
          ? {
              ...user,
              role: response.data.role,
              permissions: response.data.permissions,
            }
          : user,
      );
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const userEmailAddressAvailabilityCheck = createAsyncThunk<
  any,
  { emailAddress: string },
  {
    state: RootState;
  }
>("company/userEmailAddressAvailabilityCheck", async ({ emailAddress }) => {
  try {
    const response = await http.get(
      `/email/email-address/${emailAddress}}/availability`,
    );

    return response.data;
  } catch (error: any) {
    return error;
  }
});
