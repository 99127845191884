// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

interface FiltersState {
  [key: string]: {
    visible: boolean;
  };
}

const initialState: FiltersState = {};

export const globalFiltersSideBar = createSlice({
  name: "globalTableWidthsFilters",
  initialState,

  reducers: {
    toggleFiltersSideBar: (state, action) => {
      const location = action.payload;

      if (!state[location]) {
        state[location] = {
          visible: false,
        };
      } else {
        state[location].visible = !state[location].visible;
      }
    },

    // New action to clear the filter state for a given location
    clearFiltersForLocation: (state, action) => {
      const location = action.payload;

      // Delete the location filter state
      delete state[location];
    },
  },
  extraReducers: (builder) => {},
});

export const { toggleFiltersSideBar, clearFiltersForLocation } =
  globalFiltersSideBar.actions;
export default globalFiltersSideBar.reducer;
