import { createSlice } from "@reduxjs/toolkit";
import {
  applicantApplicationActions,
  createApplicationComment,
  deleteApplicationComment,
  followUnfollowApplication,
  getApplicantApplication,
  getApplicantApplicationComments,
  getApplicantApplicationModule,
  getApplicantApplications,
  getApplicantProfile,
  getApplicantTestResults,
  handleFinishScoringLiveInterview,
  handleLiveInterviewScoring,
  handleSubmitFinishScoring,
  handleSubmitMicrodimensionScore,
  recalculatePreinterviewResults,
  recalculateResults,
  refreshApplicantApplicationModule,
  refreshApplicantTestResults,
  retryApplicationAssessment,
  updateApplicationComment,
} from "./applicant.actions";
import {
  actionsApplicationComments,
  applicantApplicationModuleReducerType,
  applicantApplicationReducerType,
  applicantApplicationsReducerType,
  applicantProfileReducerType,
  applicantRecalculatedResultsReducerType,
  applicantScreeningType,
  applicantTestResultsReducerType,
  applicationActions,
  followApplicationInterface,
  listApplicationComments,
} from "../applicant.types";

interface reducerType {
  profile: applicantProfileReducerType;
  applications: applicantApplicationsReducerType;
  application: applicantApplicationReducerType;
  module: applicantApplicationModuleReducerType;
  testResults: applicantTestResultsReducerType;
  liveInterviewScore: applicantTestResultsReducerType;
  applicantScreeningIds: applicantScreeningType | null;
  recalculatedResults: applicantRecalculatedResultsReducerType;
  createApplicationComments: actionsApplicationComments;
  listApplicationComments: listApplicationComments;
  actionsApplication: applicationActions;
  followApplication: followApplicationInterface;
}

const initialState: reducerType = {
  profile: {
    value: null,
    loading: false,
    error: null,
  },
  applications: {
    value: null,
    loading: false,
    error: null,
  },
  application: {
    value: null,
    loading: false,
    error: null,
  },
  module: {
    value: null,
    loading: false,
    error: null,
  },
  testResults: {
    value: null,
    loading: false,
    processing: false,
    scoreProcessing: false,
    linkQuestion: "",
    error: null,
  },
  liveInterviewScore: {
    value: null,
    loading: false,
    processing: false,
    linkQuestion: "",
    error: null,
  },
  applicantScreeningIds: null,
  recalculatedResults: {
    value: null,
    processing: false,
    error: null,
  },
  createApplicationComments: {
    value: null,
    processing: false,
    error: null,
  },
  listApplicationComments: {
    value: null,
    processing: false,
    error: null,
  },
  actionsApplication: {
    processing: false,
    error: null,
  },
  followApplication: {
    processing: false,
    error: null,
  },
};

export const applicant = createSlice({
  name: "applicant",
  initialState: initialState,
  reducers: {
    clearApplicant: () => initialState,
    clearApplicantApplication: (state) => {
      state.application = initialState.application;
    },
    clearApplicantApplicationModule: (state) => {
      state.module = initialState.module;
    },
    clearApplicantTestResults: (state) => {
      state.testResults = initialState.testResults;
    },
    setApplicantScreeningIds: (state, { payload }) => {
      state.applicantScreeningIds = payload;
    },
    clearApplicantScreeningIds: (state) => {
      state.applicantScreeningIds = null;
    },
    clearRecalculatedResults: (state) => {
      state.recalculatedResults = initialState.recalculatedResults;
    },
    updateTestResults: (state, { payload }) => {
      state.testResults.value = {
        ...state.testResults.value,
        results: payload.results,
        canFinishScoring: payload.canFinishScoring,
      };
    },
    setLinkQuestion: (state, { payload }) => {
      state.testResults.linkQuestion = payload;
    },
    updateApplicationResults: (state, { payload }) => {
      state.application.value = {
        ...state.application.value,
        results: payload,
      };
    },
    updateApplicationOverallScore: (state, { payload }) => {
      state.application.value = {
        ...state.application.value,
        results: {
          ...state.application.value.results,
          finalScore: payload.overallScore,
        },
      };
    },
    updateApplicationStepFinalScore: (state, { payload }) => {
      state.module.value = {
        ...state.module.value,
        userData: {
          ...state.module.value.userData,
          results: {
            ...state.module.value.userData,
            finalsScore: payload.finalScore,
          },
        },
      };
    },

    updateApplicationStepResults: (state, { payload }) => {
      state.module.value = {
        ...state.module.value,
        userData: {
          ...state.module.value.userData,
          results: payload.stepResults,
        },
        moduleState: payload.moduleState,
        scoredAt: payload.scoredAt,
      };
    },
    updateApplicationStepTestsStateCompleted: (state, { payload }) => {
      state.module.value = {
        ...state.module.value,
        userData: {
          ...state.module.value.userData,
          tests: state.module.value.userData?.tests.map(
            (test: { _id: string }) =>
              test._id === payload
                ? { ...test, appliedState: "COMPLETED" }
                : test,
          ),
        },
      };
    },
    updateModuleTestResults: (state, { payload }) => {
      state.module.value = {
        ...state.module.value,
        userData: {
          ...state.module.value.userData,
          tests: state.module.value.userData.tests.map(
            (test: { _id: string }) =>
              test._id === payload.id
                ? {
                    ...test,
                    results: payload.results,
                  }
                : test,
          ),
        },
      };
    },
    updateApplicationHiringFlowStatus: (state, { payload }) => {
      state.application.value = {
        ...state.application.value,
        hiringFlow: {
          ...state.application?.value?.hiringFlow,
          applicationStepModules:
            state.application?.value?.hiringFlow.applicationStepModules.map(
              (step: { _id: string }) =>
                step._id === payload.id
                  ? { ...step, moduleState: payload.moduleState }
                  : step,
            ),
          state: payload?.hiringFlowSate,
        },
      };
    },
    updateLiveInterviewModuleValue: (state, { payload }) => {
      state.module.value = {
        ...state.module.value,
        [payload.flag]: payload.value,
      };
    },
    updateLiveInterviewModuleMicrodimensions: (state, { payload }) => {
      state.module.value = {
        ...state.module.value,
        userData: {
          ...state.module.value.userData,
          microdimensions: payload.microdimensions,
        },
      };
    },
    updateApplicationHiringFlowLiveInterview: (state, { payload }) => {
      state.application.value = {
        ...state.application.value,
        hiringFlow: {
          ...state.application?.value?.hiringFlow,
          applicationStepModules:
            state.application?.value?.hiringFlow.applicationStepModules.map(
              (step: { _id: string; userData?: any }) =>
                step._id === payload.id
                  ? {
                      ...step,
                      userData: {
                        ...step.userData,
                        results: {
                          finalScore: payload.finalScore,
                        },
                      },
                    }
                  : step,
            ),
          state: payload?.hiringFlowSate,
        },
      };
    },
    updateApplicationModuleState: (state, { payload }) => {
      state.module.value = {
        ...state.module.value,
        moduleState: payload,
      };
    },
    updateApplicationState: (state, { payload }) => {
      state.application.value = {
        ...state.application.value,
        hiringFlow: {
          ...state.application?.value?.hiringFlow,
          applicationStepModules:
            state.application?.value?.hiringFlow.applicationStepModules.map(
              (step: { _id: string }) =>
                step._id === payload.id
                  ? { ...step, moduleState: payload.moduleState }
                  : step,
            ),
        },
      };
    },
    setApplicantApplicationComments(state, { payload }) {
      state.application.value = {
        ...state?.application?.value,
        comments: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicantProfile.pending, (state) => {
        state.profile.loading = true;
      })
      .addCase(getApplicantProfile.fulfilled, (state, { payload }) => {
        state.profile.value = payload;
        state.profile.loading = false;
      })
      .addCase(getApplicantProfile.rejected, (state, { payload }) => {
        state.profile.error = payload;
        state.profile.loading = false;
      })
      .addCase(getApplicantApplications.pending, (state) => {
        state.applications.loading = true;
      })
      .addCase(getApplicantApplications.fulfilled, (state, { payload }) => {
        state.applications.value = payload;
        state.applications.loading = false;
      })
      .addCase(getApplicantApplications.rejected, (state, { payload }) => {
        state.applications.error = payload;
        state.applications.loading = false;
      })
      .addCase(getApplicantApplication.pending, (state) => {
        state.application.loading = true;
      })
      .addCase(getApplicantApplication.fulfilled, (state, { payload }) => {
        state.application.value = payload;
        state.application.loading = false;
      })
      .addCase(getApplicantApplication.rejected, (state, { payload }) => {
        state.application.error = payload;
        state.application.loading = false;
      })
      .addCase(getApplicantApplicationModule.pending, (state) => {
        state.module.loading = true;
      })
      .addCase(
        getApplicantApplicationModule.fulfilled,
        (state, { payload }) => {
          state.module.value = payload;
          state.module.loading = false;
        },
      )
      .addCase(getApplicantApplicationModule.rejected, (state, { payload }) => {
        state.module.error = payload;
        state.module.loading = false;
      })
      .addCase(getApplicantTestResults.pending, (state) => {
        state.testResults.loading = true;
      })
      .addCase(getApplicantTestResults.fulfilled, (state, { payload }) => {
        state.testResults.value = payload;
        state.testResults.loading = false;
      })
      .addCase(getApplicantTestResults.rejected, (state, { payload }) => {
        state.testResults.loading = false;
        state.testResults.error = payload;
      })
      .addCase(refreshApplicantTestResults.pending, (state) => {
        state.testResults.scoreProcessing = true;
      })
      .addCase(refreshApplicantTestResults.fulfilled, (state, { payload }) => {
        state.testResults.value = payload;
        state.testResults.scoreProcessing = false;
      })
      .addCase(refreshApplicantTestResults.rejected, (state, { payload }) => {
        state.testResults.error = payload;
        state.testResults.scoreProcessing = false;
      })
      .addCase(handleSubmitMicrodimensionScore.pending, (state) => {
        state.testResults.processing = true;
      })
      .addCase(handleSubmitMicrodimensionScore.fulfilled, (state) => {
        state.testResults.processing = false;
      })
      .addCase(
        handleSubmitMicrodimensionScore.rejected,
        (state, { payload }) => {
          state.testResults.processing = false;
          state.testResults.error = payload;
        },
      )
      .addCase(handleSubmitFinishScoring.pending, (state) => {
        state.testResults.processing = true;
      })
      .addCase(handleSubmitFinishScoring.fulfilled, (state, { payload }) => {
        state.testResults.value = {
          ...state.testResults.value,
          results: payload.results,
          appliedState: payload.appliedState,
          isManualScoringFinished: payload.isManualScoringFinished,
          scoredAt: payload?.scoredAt,
        };
        state.testResults.processing = false;
      })
      .addCase(handleSubmitFinishScoring.rejected, (state, { payload }) => {
        state.testResults.processing = false;
        state.testResults.error = payload;
      })
      .addCase(handleLiveInterviewScoring.pending, (state) => {
        state.liveInterviewScore.processing = true;
      })
      .addCase(handleLiveInterviewScoring.fulfilled, (state) => {
        state.liveInterviewScore.processing = false;
      })
      .addCase(handleLiveInterviewScoring.rejected, (state, { payload }) => {
        state.liveInterviewScore.processing = false;
        state.liveInterviewScore.error = payload;
      })
      .addCase(
        refreshApplicantApplicationModule.fulfilled,
        (state, { payload }) => {
          state.module.value = payload;
        },
      )
      .addCase(
        refreshApplicantApplicationModule.rejected,
        (state, { payload }) => {
          state.module.error = payload;
        },
      )
      .addCase(handleFinishScoringLiveInterview.pending, (state) => {
        state.testResults.processing = true;
        state.liveInterviewScore.processing = true;
      })
      .addCase(
        handleFinishScoringLiveInterview.fulfilled,
        (state, { payload }) => {
          state.testResults.processing = false;
          state.liveInterviewScore.processing = false;
        },
      )
      .addCase(
        handleFinishScoringLiveInterview.rejected,
        (state, { payload }) => {
          state.testResults.processing = false;
          state.liveInterviewScore.processing = false;
          state.liveInterviewScore.error = payload;
          state.testResults.error = payload;
        },
      )
      .addCase(recalculateResults.pending, (state) => {
        state.recalculatedResults.processing = true;
      })
      .addCase(recalculateResults.fulfilled, (state, { payload }) => {
        state.recalculatedResults.value = payload;
        state.recalculatedResults.processing = false;
      })
      .addCase(recalculateResults.rejected, (state, { payload }) => {
        state.recalculatedResults.processing = false;
        state.recalculatedResults.error = payload;
      })

      .addCase(recalculatePreinterviewResults.pending, (state) => {
        state.recalculatedResults.processing = true;
      })
      .addCase(
        recalculatePreinterviewResults.fulfilled,
        (state, { payload }) => {
          if (payload?.type === "manualScoring") {
            state.testResults = {
              ...state.testResults,
              value: payload.data,
            };
          } else {
            state.module.value = {
              ...state.module.value,
              userData: {
                ...state.module.value.userData,
                tests: payload,
              },
            };
          }
          state.recalculatedResults.processing = false;
        },
      )
      .addCase(
        recalculatePreinterviewResults.rejected,
        (state, { payload }) => {
          state.recalculatedResults.processing = false;
          state.recalculatedResults.error = payload;
        },
      )

      .addCase(createApplicationComment.pending, (state) => {
        state.createApplicationComments.processing = true;
      })
      .addCase(createApplicationComment.fulfilled, (state, { payload }) => {
        state.listApplicationComments = {
          ...state?.listApplicationComments,
          value: [payload, ...state?.listApplicationComments?.value],
        };
        state.createApplicationComments.processing = false;
      })
      .addCase(createApplicationComment.rejected, (state, { payload }) => {
        state.createApplicationComments.error = payload;
        state.createApplicationComments.processing = false;
      })

      .addCase(getApplicantApplicationComments.pending, (state) => {
        state.listApplicationComments.processing = true;
      })
      .addCase(
        getApplicantApplicationComments.fulfilled,
        (state, { payload }) => {
          state.listApplicationComments.value = payload;
          state.listApplicationComments.processing = false;
        },
      )
      .addCase(
        getApplicantApplicationComments.rejected,
        (state, { payload }) => {
          state.listApplicationComments.error = payload;
          state.listApplicationComments.processing = false;
        },
      )
      .addCase(deleteApplicationComment.pending, (state) => {
        state.createApplicationComments.processing = true;
      })
      .addCase(deleteApplicationComment.fulfilled, (state, { payload }) => {
        state.listApplicationComments = {
          ...state?.listApplicationComments,
          value: state?.listApplicationComments?.value?.filter(
            (item: { _id: string }) => item._id !== payload?.idComment,
          ),
        };
        state.createApplicationComments.processing = false;
      })
      .addCase(deleteApplicationComment.rejected, (state, { payload }) => {
        state.createApplicationComments.error = payload;
        state.createApplicationComments.processing = false;
      })

      .addCase(updateApplicationComment.pending, (state) => {
        state.createApplicationComments.processing = true;
      })
      .addCase(updateApplicationComment.fulfilled, (state, { payload }) => {
        state.listApplicationComments = {
          ...state?.listApplicationComments,
          value: payload,
        };
        state.createApplicationComments.processing = false;
      })
      .addCase(updateApplicationComment.rejected, (state, { payload }) => {
        state.createApplicationComments.error = payload;
        state.createApplicationComments.processing = false;
      })
      .addCase(applicantApplicationActions.pending, (state) => {
        state.actionsApplication.processing = true;
      })
      .addCase(applicantApplicationActions.fulfilled, (state, { payload }) => {
        state.actionsApplication.processing = false;
      })
      .addCase(applicantApplicationActions.rejected, (state, { payload }) => {
        state.actionsApplication.error = payload;
        state.actionsApplication.processing = false;
      })

      .addCase(retryApplicationAssessment.pending, (state) => {
        state.actionsApplication.processing = true;
      })
      .addCase(retryApplicationAssessment.fulfilled, (state, { payload }) => {
        state.actionsApplication.processing = false;
      })
      .addCase(retryApplicationAssessment.rejected, (state, { payload }) => {
        state.actionsApplication.error = payload;
        state.actionsApplication.processing = false;
      })

      .addCase(followUnfollowApplication.pending, (state) => {
        state.followApplication.processing = true;
      })
      .addCase(followUnfollowApplication.fulfilled, (state, { payload }) => {
        state.application = {
          ...state?.application,
          value: {
            ...state.application.value,
            follower: payload || null,
          },
        };
        state.followApplication.processing = false;
      })
      .addCase(followUnfollowApplication.rejected, (state, { payload }) => {
        state.followApplication.error = payload;
        state.followApplication.processing = false;
      });
  },
});

export const {
  clearApplicant,
  clearApplicantApplication,
  clearApplicantApplicationModule,
  clearApplicantTestResults,
  setApplicantScreeningIds,
  clearApplicantScreeningIds,
  clearRecalculatedResults,
  setLinkQuestion,
  updateTestResults,
  updateModuleTestResults,
  updateApplicationResults,
  updateApplicationStepResults,
  updateApplicationStepTestsStateCompleted,
  updateApplicationHiringFlowStatus,
  updateLiveInterviewModuleValue,
  updateLiveInterviewModuleMicrodimensions,
  updateApplicationHiringFlowLiveInterview,
  updateApplicationModuleState,
  updateApplicationState,
  updateApplicationOverallScore,
  updateApplicationStepFinalScore,
  setApplicantApplicationComments,
} = applicant.actions;

export default applicant.reducer;
