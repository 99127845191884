// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import { http } from "common/api/http";
import { RootState } from "redux/store";
import { setToast } from "../../../../redux/alert";
import { isStringEmpty } from "common/utility/Utils";

interface getCompaniesParams {
  paramsArray?: any;
  signal?: any;
}
export const getCompanies = createAsyncThunk<
  any,
  getCompaniesParams,
  { state: RootState }
>("companies", async ({ paramsArray, signal }, { rejectWithValue }) => {
  try {
    const params = {
      page: paramsArray?.page || 1,
      ...(paramsArray?.filters?.status && {
        state: paramsArray?.filters?.status?.toUpperCase(),
      }),
      limit: 50,
      ...(paramsArray?.search && {
        search: paramsArray?.search,
      }),
      ...(paramsArray?.sort && {
        sort: paramsArray?.sort,
      }),
    };
    const response = await http.get(`/companies`, { params, signal });

    if (!response?.data) return;
    return response?.data;
  } catch (error: any) {
    return rejectWithValue({
      ...error.data,
      code: error.code,
      method: error.config.method,
      type: "CUSTOM",
      style: "MAIN_CONTENT",
    });
  }
});

export interface companyWizardInput {
  name: string;
  address: string;
  websiteUrl: string;
  emailAddress: string;
  logo: string;
  color: string;
  tests: string[];
}

export const createCompany = createAsyncThunk(
  "company/createCompany",
  async (
    {
      name,
      address,
      websiteUrl,
      emailAddress,
      logo,
      color,
      tests,
    }: companyWizardInput,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.post("/companies", {
        name,
        address,
        websiteUrl,
        ...(!isStringEmpty(emailAddress) && {
          emailAddress: emailAddress,
        }),
        logo,
        color,
        tests,
      });
      dispatch(
        setToast({
          message: "Company successfully created.",
          type: "success",
        }),
      );
      window.location.pathname = "/companies";
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  },
);

export const companyEmailAddressAvailabilityCheck = createAsyncThunk<
  any,
  { domain: string },
  {
    state: RootState;
  }
>("company/companyEmailAddressAvailabilityCheck", async ({ domain }) => {
  try {
    const response = await http.get(`/email/domain/${domain}/availability`);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

// export const companyEmailAddressAvailabilityCheck = createAsyncThunk<
//   any,
//   { emailAddress: string },
//   {
//     state: RootState;
//   }
// >("company/companyEmailAddressAvailabilityCheck", async ({ emailAddress }) => {
//   try {
//     const response = await http.get(
//       `/email/email-address/${emailAddress}/availability`
//     );
//     return response.data;
//   } catch (error: any) {
//     return error;
//   }
// });

export const suspendCompany = createAsyncThunk<
  any,
  { id: string },
  {
    state: RootState;
  }
>(
  "company/suspendCompany",
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await http.patch(`/companies/${id}/suspend`);
      dispatch(
        setToast({
          message: "Successfully suspended company.",
          type: "success",
        }),
      );

      const values = getState().companies.companiesList.values?.data;
      return values?.map((company: any) =>
        company._id === response.data._id
          ? { ...company, state: response.data.state }
          : company,
      );
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const activateCompany = createAsyncThunk<
  any,
  { id: string },
  {
    state: RootState;
  }
>(
  "company/activateCompany",
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await http.patch(`/companies/${id}/activate`);
      dispatch(
        setToast({
          message: "Successfully activated company.",
          type: "success",
        }),
      );
      const values = getState().companies.companiesList.values?.data;
      return values?.map((company: any) =>
        company._id === response.data._id
          ? { ...company, state: response.data.state }
          : company,
      );
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const updateCompany = createAsyncThunk<
  any,
  { id: string; tests: string[] },
  {
    state: RootState;
  }
>(
  "company/updateCompany",
  async ({ id, tests }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response: any = await http.patch(`/companies/${id}`, {
        tests,
      });
      dispatch(
        setToast({
          message: "Successfully updated company.",
          type: "success",
        }),
      );

      const values = getState().companies.companiesList.values?.data;
      return values?.map((company: any) =>
        company._id === response.data._id ? response.data : company,
      );
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);
